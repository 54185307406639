import request from "../../../common/utils/request";

// 获取设备库信息
export function getEquipments(params) {
  return request({
    url: "/itf/common/cjrh/equipment/getEquipments.json",
    method: "get",
    params: params,
  });
}
// 获取设备库信息
export function getEquipmentById(params) {
  return request({
    url: "/itf/common/cjrh/equipment/getEquipmentById.json",
    method: "get",
    params: params,
  });
}